export const HubAddress = () => import('../../components/attributes/HubAddress.vue' /* webpackChunkName: "components/hub-address" */).then(c => wrapFunctional(c.default || c))
export const HubAttributeLoader = () => import('../../components/attributes/HubAttributeLoader.vue' /* webpackChunkName: "components/hub-attribute-loader" */).then(c => wrapFunctional(c.default || c))
export const HubAttributeTypeConfig = () => import('../../components/attributes/HubAttributeTypeConfig.vue' /* webpackChunkName: "components/hub-attribute-type-config" */).then(c => wrapFunctional(c.default || c))
export const HubAttributeTypeConfigOptions = () => import('../../components/attributes/HubAttributeTypeConfigOptions.vue' /* webpackChunkName: "components/hub-attribute-type-config-options" */).then(c => wrapFunctional(c.default || c))
export const HubAttributeTypeConfigWysiwygCustomElements = () => import('../../components/attributes/HubAttributeTypeConfigWysiwygCustomElements.vue' /* webpackChunkName: "components/hub-attribute-type-config-wysiwyg-custom-elements" */).then(c => wrapFunctional(c.default || c))
export const HubCheckbox = () => import('../../components/attributes/HubCheckbox.vue' /* webpackChunkName: "components/hub-checkbox" */).then(c => wrapFunctional(c.default || c))
export const HubCheckboxList = () => import('../../components/attributes/HubCheckboxList.vue' /* webpackChunkName: "components/hub-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const HubColor = () => import('../../components/attributes/HubColor.vue' /* webpackChunkName: "components/hub-color" */).then(c => wrapFunctional(c.default || c))
export const HubDatepicker = () => import('../../components/attributes/HubDatepicker.vue' /* webpackChunkName: "components/hub-datepicker" */).then(c => wrapFunctional(c.default || c))
export const HubDatetimepicker = () => import('../../components/attributes/HubDatetimepicker.vue' /* webpackChunkName: "components/hub-datetimepicker" */).then(c => wrapFunctional(c.default || c))
export const HubDropdown = () => import('../../components/attributes/HubDropdown.vue' /* webpackChunkName: "components/hub-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HubEmail = () => import('../../components/attributes/HubEmail.vue' /* webpackChunkName: "components/hub-email" */).then(c => wrapFunctional(c.default || c))
export const HubEntities = () => import('../../components/attributes/HubEntities.vue' /* webpackChunkName: "components/hub-entities" */).then(c => wrapFunctional(c.default || c))
export const HubFile = () => import('../../components/attributes/HubFile.vue' /* webpackChunkName: "components/hub-file" */).then(c => wrapFunctional(c.default || c))
export const HubFiles = () => import('../../components/attributes/HubFiles.vue' /* webpackChunkName: "components/hub-files" */).then(c => wrapFunctional(c.default || c))
export const HubIcons = () => import('../../components/attributes/HubIcons.vue' /* webpackChunkName: "components/hub-icons" */).then(c => wrapFunctional(c.default || c))
export const HubImage = () => import('../../components/attributes/HubImage.vue' /* webpackChunkName: "components/hub-image" */).then(c => wrapFunctional(c.default || c))
export const HubImages = () => import('../../components/attributes/HubImages.vue' /* webpackChunkName: "components/hub-images" */).then(c => wrapFunctional(c.default || c))
export const HubIntegration = () => import('../../components/attributes/HubIntegration.vue' /* webpackChunkName: "components/hub-integration" */).then(c => wrapFunctional(c.default || c))
export const HubJson = () => import('../../components/attributes/HubJson.vue' /* webpackChunkName: "components/hub-json" */).then(c => wrapFunctional(c.default || c))
export const HubLanguage = () => import('../../components/attributes/HubLanguage.vue' /* webpackChunkName: "components/hub-language" */).then(c => wrapFunctional(c.default || c))
export const HubLatlng = () => import('../../components/attributes/HubLatlng.vue' /* webpackChunkName: "components/hub-latlng" */).then(c => wrapFunctional(c.default || c))
export const HubLink = () => import('../../components/attributes/HubLink.vue' /* webpackChunkName: "components/hub-link" */).then(c => wrapFunctional(c.default || c))
export const HubMultipleinputtype = () => import('../../components/attributes/HubMultipleinputtype.vue' /* webpackChunkName: "components/hub-multipleinputtype" */).then(c => wrapFunctional(c.default || c))
export const HubOpeninghours = () => import('../../components/attributes/HubOpeninghours.vue' /* webpackChunkName: "components/hub-openinghours" */).then(c => wrapFunctional(c.default || c))
export const HubOrdering = () => import('../../components/attributes/HubOrdering.vue' /* webpackChunkName: "components/hub-ordering" */).then(c => wrapFunctional(c.default || c))
export const HubOrgUserSelect = () => import('../../components/attributes/HubOrgUserSelect.vue' /* webpackChunkName: "components/hub-org-user-select" */).then(c => wrapFunctional(c.default || c))
export const HubPool = () => import('../../components/attributes/HubPool.vue' /* webpackChunkName: "components/hub-pool" */).then(c => wrapFunctional(c.default || c))
export const HubPoolTypes = () => import('../../components/attributes/HubPoolTypes.vue' /* webpackChunkName: "components/hub-pool-types" */).then(c => wrapFunctional(c.default || c))
export const HubRadiolist = () => import('../../components/attributes/HubRadiolist.vue' /* webpackChunkName: "components/hub-radiolist" */).then(c => wrapFunctional(c.default || c))
export const HubSchema = () => import('../../components/attributes/HubSchema.vue' /* webpackChunkName: "components/hub-schema" */).then(c => wrapFunctional(c.default || c))
export const HubSchemas = () => import('../../components/attributes/HubSchemas.vue' /* webpackChunkName: "components/hub-schemas" */).then(c => wrapFunctional(c.default || c))
export const HubSlug = () => import('../../components/attributes/HubSlug.vue' /* webpackChunkName: "components/hub-slug" */).then(c => wrapFunctional(c.default || c))
export const HubStream = () => import('../../components/attributes/HubStream.vue' /* webpackChunkName: "components/hub-stream" */).then(c => wrapFunctional(c.default || c))
export const HubTag = () => import('../../components/attributes/HubTag.vue' /* webpackChunkName: "components/hub-tag" */).then(c => wrapFunctional(c.default || c))
export const HubTags = () => import('../../components/attributes/HubTags.vue' /* webpackChunkName: "components/hub-tags" */).then(c => wrapFunctional(c.default || c))
export const HubTel = () => import('../../components/attributes/HubTel.vue' /* webpackChunkName: "components/hub-tel" */).then(c => wrapFunctional(c.default || c))
export const HubText = () => import('../../components/attributes/HubText.vue' /* webpackChunkName: "components/hub-text" */).then(c => wrapFunctional(c.default || c))
export const HubTextarea = () => import('../../components/attributes/HubTextarea.vue' /* webpackChunkName: "components/hub-textarea" */).then(c => wrapFunctional(c.default || c))
export const HubUrl = () => import('../../components/attributes/HubUrl.vue' /* webpackChunkName: "components/hub-url" */).then(c => wrapFunctional(c.default || c))
export const HubWysiwyg = () => import('../../components/attributes/HubWysiwyg.vue' /* webpackChunkName: "components/hub-wysiwyg" */).then(c => wrapFunctional(c.default || c))
export const HubWysiwygBubbleMenu = () => import('../../components/attributes/HubWysiwygBubbleMenu.vue' /* webpackChunkName: "components/hub-wysiwyg-bubble-menu" */).then(c => wrapFunctional(c.default || c))
export const HubWysiwygEditorWrapper = () => import('../../components/attributes/HubWysiwygEditorWrapper.vue' /* webpackChunkName: "components/hub-wysiwyg-editor-wrapper" */).then(c => wrapFunctional(c.default || c))
export const HubWysiwygFullScreen = () => import('../../components/attributes/HubWysiwygFullScreen.vue' /* webpackChunkName: "components/hub-wysiwyg-full-screen" */).then(c => wrapFunctional(c.default || c))
export const HubYoutube = () => import('../../components/attributes/HubYoutube.vue' /* webpackChunkName: "components/hub-youtube" */).then(c => wrapFunctional(c.default || c))
export const BaseWidgetConfig = () => import('../../components/widgets/BaseWidgetConfig.vue' /* webpackChunkName: "components/base-widget-config" */).then(c => wrapFunctional(c.default || c))
export const DashboardConfig = () => import('../../components/widgets/DashboardConfig.vue' /* webpackChunkName: "components/dashboard-config" */).then(c => wrapFunctional(c.default || c))
export const GridCol = () => import('../../components/widgets/GridCol.vue' /* webpackChunkName: "components/grid-col" */).then(c => wrapFunctional(c.default || c))
export const GridColConfig = () => import('../../components/widgets/GridColConfig.vue' /* webpackChunkName: "components/grid-col-config" */).then(c => wrapFunctional(c.default || c))
export const GridRow = () => import('../../components/widgets/GridRow.vue' /* webpackChunkName: "components/grid-row" */).then(c => wrapFunctional(c.default || c))
export const GridRowConfig = () => import('../../components/widgets/GridRowConfig.vue' /* webpackChunkName: "components/grid-row-config" */).then(c => wrapFunctional(c.default || c))
export const ImageView = () => import('../../components/widgets/ImageView.vue' /* webpackChunkName: "components/image-view" */).then(c => wrapFunctional(c.default || c))
export const ImageViewConfig = () => import('../../components/widgets/ImageViewConfig.vue' /* webpackChunkName: "components/image-view-config" */).then(c => wrapFunctional(c.default || c))
export const Integration = () => import('../../components/widgets/Integration.vue' /* webpackChunkName: "components/integration" */).then(c => wrapFunctional(c.default || c))
export const IntegrationConfig = () => import('../../components/widgets/IntegrationConfig.vue' /* webpackChunkName: "components/integration-config" */).then(c => wrapFunctional(c.default || c))
export const NoPermissions = () => import('../../components/widgets/NoPermissions.vue' /* webpackChunkName: "components/no-permissions" */).then(c => wrapFunctional(c.default || c))
export const Plausible = () => import('../../components/widgets/Plausible.vue' /* webpackChunkName: "components/plausible" */).then(c => wrapFunctional(c.default || c))
export const PlausibleConfig = () => import('../../components/widgets/PlausibleConfig.vue' /* webpackChunkName: "components/plausible-config" */).then(c => wrapFunctional(c.default || c))
export const PoolList = () => import('../../components/widgets/PoolList.vue' /* webpackChunkName: "components/pool-list" */).then(c => wrapFunctional(c.default || c))
export const PoolListConfig = () => import('../../components/widgets/PoolListConfig.vue' /* webpackChunkName: "components/pool-list-config" */).then(c => wrapFunctional(c.default || c))
export const StreamInline = () => import('../../components/widgets/StreamInline.vue' /* webpackChunkName: "components/stream-inline" */).then(c => wrapFunctional(c.default || c))
export const StreamInlineConfig = () => import('../../components/widgets/StreamInlineConfig.vue' /* webpackChunkName: "components/stream-inline-config" */).then(c => wrapFunctional(c.default || c))
export const WidgetsList = () => import('../../components/widgets/WidgetsList.vue' /* webpackChunkName: "components/widgets-list" */).then(c => wrapFunctional(c.default || c))
export const Automations = () => import('../../components/dashboard/Automations.vue' /* webpackChunkName: "components/automations" */).then(c => wrapFunctional(c.default || c))
export const EntitiesContents = () => import('../../components/dashboard/EntitiesContents.vue' /* webpackChunkName: "components/entities-contents" */).then(c => wrapFunctional(c.default || c))
export const EntitiesStats = () => import('../../components/dashboard/EntitiesStats.vue' /* webpackChunkName: "components/entities-stats" */).then(c => wrapFunctional(c.default || c))
export const LiveMetrics = () => import('../../components/dashboard/LiveMetrics.vue' /* webpackChunkName: "components/live-metrics" */).then(c => wrapFunctional(c.default || c))
export const LiveMetricsItem = () => import('../../components/dashboard/LiveMetricsItem.vue' /* webpackChunkName: "components/live-metrics-item" */).then(c => wrapFunctional(c.default || c))
export const PopularContent = () => import('../../components/dashboard/PopularContent.vue' /* webpackChunkName: "components/popular-content" */).then(c => wrapFunctional(c.default || c))
export const Requests = () => import('../../components/dashboard/Requests.vue' /* webpackChunkName: "components/requests" */).then(c => wrapFunctional(c.default || c))
export const Schedule = () => import('../../components/dashboard/Schedule.vue' /* webpackChunkName: "components/schedule" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
